.react-calendar {
  border-radius: 5px;
  border: 2px solid white;
}

.react-calendar__tile {
  border-radius: 50%;
}

.react-calendar__tile--active {
  border-radius: 50%;
  background-color: #8ec044;
}
