.panda-fa-icon-button {
  color    : var(--panda-fa-icon-button-color);
  font-size: var(--panda-fa-icon-button-size);
  margin   : 5px;
  cursor   : pointer;
  position : relative;
  display: flex;

  .badge {
    width          : 1.5rem;
    height         : 1.5rem;
    // border         : 1px solid;
    border-radius  : 50%;
    display        : flex;
    justify-content: center;
    align-items    : center;
    background-color: #f8f8f8;
  }
}