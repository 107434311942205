.add-playlist-modal {

  .sub-title {
    font-size  : 1.2rem;
    user-select: none;
    text-align : left;
    font-weight: 600;
  }

  .select-day {
    display    : flex;
    margin-left: 2rem;
  }
}