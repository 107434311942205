.panda-button {
  $backgoundColor: var(--panda-button-background);
  $pandaButtonJustify: var(--panda-button-justify-content);
  $pandaButtonTextColor: var(--panda-button-text-color);

  cursor: pointer;
  font-weight: 600;
  // margin: 5px;
  position: relative;
  display: flex;
  background-color: $backgoundColor;
  justify-content: $pandaButtonJustify;
  user-select: none;
  max-height: 2rem;
  border-radius: 5px;

  &.disabled {
    cursor: default;
  }

  .label {
    display: flex;
    padding: 5px;
    color: $pandaButtonTextColor;
    display: inline;

    i {
      margin: 0 5px 0 0;
    }
  }

  &:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
  }

  .last-fix {
    position: absolute;
    padding: 0 5px;
    top: 0;
    right: 0;
  }
}
