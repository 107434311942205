.panda-spin-input {
  $panda-span-size: 25px;
  font-size       : 14px;
  width           : $panda-span-size;
  display         : flex;
  flex-direction  : column;
  justify-content : center;

  .action {
    width          : $panda-span-size;
    height         : $panda-span-size;
    display        : flex;
    justify-content: center;
    align-items    : center;
    cursor         : pointer;
    color          : var(--panda-spin-input-color);
  }

  .edit-panel {
    width   : $panda-span-size*3;
    height  : $panda-span-size*3;
    position: relative;
    overflow: hidden;

    &:after {
      content : '';
      top     : $panda-span-size;
      position: absolute;
      width   : $panda-span-size;
      height  : $panda-span-size;
      border  : 1px solid;
      color   : var(--panda-spin-input-color);
    }

    .move-panel {
      top     : -$panda-span-size;
      position: absolute;
      width   : $panda-span-size;

      .item {
        width          : $panda-span-size;
        height         : $panda-span-size;
        display        : flex;
        justify-content: center;
        align-items    : center;
        color          : var(--panda-spin-input-text-color);
      }

      &.up {
        transition: all 0.5s;
        transform : translateY(-$panda-span-size);
      }

      &.down {
        transition: all 0.5s;
        transform : translateY($panda-span-size);
      }
    }
  }
}