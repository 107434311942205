.panda-checkbox {
  display: flex;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      & ~ .check-mark {
        background-color: transparent;

        &:after {
          display: block;
        }
      }
    }

    &:disabled {
      & ~ .check-mark {
        border: solid gray;
      }

      & ~ span {
        color: gray;
      }
    }
  }

  .check-mark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: solid var(--panda-checkbox-color);
    box-sizing: border-box;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 4px;
      top: 0px;
      width: 7px;
      height: 11px;
      border: solid var(--panda-checkbox-color);
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  &:hover {
    .check-mark {
      background-color: #ccc;
    }
  }
}
