.edit-overlay-modal {
  .subject {
    display: flex;

    .icon {
      width: 25px;
    }
  }

  .content {
    height: calc(70vh - 3rem);
    width: 80vw;
  }
  .panda-button-staging {
    font-size: 14px;
    border-radius: 5px;
    background-color: #8ec044;
    color: #2c3e50;
    margin: 5px;
    border: none;
    padding: 5px;
    font-weight: bold;
  }
}
