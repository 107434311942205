@import "./utils/util.scss";

@font-face {
  font-family: "Zekton";
  src: url("./assets/fonts/Zekton_rg.otf") format("opentype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Zekton",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

.dflex {
  display: flex;
}

.flex-end {
  justify-content: flex-end;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrap-content {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.svg-button {
  font-size: 20px;
  display: flex;
  align-items: center;
}
