.trasition-component {
  .title {
    display: flex;
  }

  .edit-panel {
    display: flex;

    .items {
      flex: 1;

      .sub-title {
        display  : flex;
        font-size: 1.2rem;
        font-weight: 600;
      }
    }
  }
}