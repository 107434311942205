.list-button {

  cursor          : pointer;
  font-weight     : 600;
  margin          : 5px 0;
  position        : relative;
  display         : flex;
  background-color: var(--panda-list-background-color);
  user-select     : none;
  align-items     : center;

  &.disabled {
    cursor: default;
  }

  &.selected {
    background-color: silver;
  }

  .icon {
    position      : relative;
    width         : 80px;
    height        : 80px;
    border        : 1px solid #ddd;
    border-radius : 50%;
    display       : inline-block;
    overflow      : hidden;
    margin        : 0 1rem 0 0;
    vertical-align: middle;
  }

  .label {
    display  : flex;
    padding  : 5px;
    color    : var(--panda-list-text-color);
    font-size: 1.2rem;
    display  : inline;
  }

  &:hover {
    box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.1);
  }

  .last-fix {
    position: absolute;
    padding : 0 5px;
    top     : 0;
    right   : 0;
  }
}