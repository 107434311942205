.overlay-modal {
  .subject {
    .icon {
      width: 50px;
    }

    .title {
      font-size: 1.3rem;
    }
  }

  .content {
    display: flex;
    height: 70vh;

    .lists-container {
      display: flex;

      .lists {
        padding: 1rem;
        flex: 1;

        .sheduled {
          font-size: 20px;
          letter-spacing: 0.005em;
          box-sizing: border-box;
          font-weight: 400;
          display: flex;
          -webkit-box-align: center;
          -webkit-align-items: center;
          align-items: center;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -webkit-flex-direction: row;
          flex-direction: row;
          width: 100%;
          height: 64px;
          max-height: 64px;
          padding: 0 16px;
          margin: 0;
          color: white;
          background-color: var(--primary-color);
        }
      }
    }

    .subject {
      display: flex;

      .edit-field-part {
        border: none;

        input {
          font-size: 1.4rem;
        }
      }
    }
  }

  .actions {
    position: absolute;
    bottom: 0;
    right: 26px;
  }

  .notify {
    position: absolute;
    bottom: 0;
    left: 26px;
  }

  .MuiTab-wrapper {
    font-size: 23px;
  }
  .overlay-row {
    display: flex;
    align-content: stretch;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
  }
}
