.panda-color-picker {
  .color-button {
    display    : flex;
    cursor     : pointer;
    user-select: none;

    .show-color {
      width           : 24px;
      height          : 24px;
      background-color: var(--panda-selected-color);
      border-radius   : 50%;
      margin-right    : 1rem;
    }
  }
}