.youtube-live {
  background-color: #fff;
  padding         : 0.5rem 1rem;
  margin-top      : 1rem;
  border-radius   : 8px;
  box-shadow      : 0 0 10px 0 rgba(199, 5, 5, 0.2);

  .title {
    font-size  : 1.5rem;
    display    : flex;
    user-select: none;
    color      : #C70505;
    justify-content: space-between;

    .icon {
      display         : flex;
      justify-content : center;
      align-items     : center;
      border-radius   : 50%;
      width           : 40px;
      height          : 40px;
      background-color: rgba(white, 0.3);
      margin-right    : 1rem;
    }

  }

  .actions {
    display        : flex;
    justify-content: center;
  }
}