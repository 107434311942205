.App {
  text-align : center;
  font-family: myriad-pro, sans-serif;
}

.App-logo {
  height        : 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height      : 100vh;
  display         : flex;
  flex-direction  : column;
  align-items     : center;
  justify-content : center;
  font-size       : calc(10px + 2vmin);
  color           : white;
}

.App-link {
  color: #61dafb;
}

* {
  &:focus {
    outline: none;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.nav-tabs {
  justify-content: space-between;

  a {
    flex: 1;
  }

  .nav-item {
    border        : 1px solid var(--primary-color);
    text-align    : center;
    color         : var(--default-font-color);
    font-weight   : 800;
    text-transform: uppercase;
    padding       : 0.5rem;
    font-size     : 0.7rem;

    i {
      padding: 0 0.2rem;
    }
  }

  .nav-item.active {
    color           : white;
    background-color: var(--primary-color);
    text-align      : center;
  }
}

.flexCol {
  display       : flex;
  flex-direction: column;
}

.flexRow {
  display       : flex;
  flex-direction: row;
  align-items   : center;
}

button:focus {
  outline: none;
}