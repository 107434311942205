.panda-loading-overlay {
  width          : 100%;
  height         : 100%;
  position       : relative;
  display        : flex;
  flex           : 1;
  justify-content: center;
  align-items    : center;

  &:after {
    position         : absolute;
    display          : flex;
    content          : "";
    border           : 16px solid #f3f3f3;
    border-radius    : 50%;
    border-top       : 16px solid #3498db;
    width            : 120px;
    height           : 120px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
  }
}


/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}