.panda-date-input {
  display        : flex;
  justify-content: flex-start;

  .panda-select {
    margin          : 0 5px 0 0;
    padding         : 0 1rem 0 0;
    border          : none;
    border-radius   : 0;
    // border-bottom: 1px solid var(--panda-date-input-color);

    .select-icon {
      top: 0
    }

    select {
      color       : var(--panda-date-text-color);
      font-weight : 600;
      margin-right: 1rem;
    }
  }

  .time {
    display      : flex;
    border-bottom: 1px solid var(--panda-date-input-color);
    margin-bottom: 5px;

    span {
      display        : flex;
      align-items    : center;
      justify-content: center;
    }

    .time-item {
      padding    : 0 3px;
      text-align : center;
      border     : none;
      border     : none;
      font-weight: 600;
      text-align : center;

      &:focus {
        outline         : none;
        text-shadow     : 0 0 0 var(--default-font-color);
        background-color: var(--panda-date-input-color);
      }
    }
  }

}

.panda-date-input-time {
  display        : flex;
  justify-content: center;
  align-items    : center;
  padding        : 5px;

  &>div+div {
    padding-left: 5px;
  }
}